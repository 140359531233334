class FilterContent {
	constructor() {
		this.body = document.body
		this.toggleBtn = this.toggleBtn.bind(this)
	}
	init() {
		this.isFilteropen = false
		this.openButton = document.querySelectorAll(
			".filter .searchandfilter > ul h4"
		)
		this.openButton.forEach(el => {
			el.addEventListener("click", this.toggleBtn)
		})
	}

	toggleBtn(event) {
		const btn = event.target.closest(".filter .searchandfilter > ul > li")
		const children = Array.from(btn.children)

		btn.classList.toggle("active")
		children.forEach(function (el) {
			el.classList.toggle("active")
		})
	}
}

export default FilterContent
