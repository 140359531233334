import "../css/style.scss"

import AOS, { init } from "aos"
import Nav from "./modules/Nav"
import MobileMenu from "./modules/MobileMenu"
import FilterContent from "./modules/FilterContent"
import * as $ from "jquery"
import Search from "./modules/Search"

const mobileMenu = new MobileMenu()
mobileMenu.init()
mobileMenu.start()
const nav = new Nav()
nav.init()
nav.start()
const filterContent = new FilterContent()
filterContent.init()
const search = new Search()

// Allow new JS and CSS to load in browser without a traditional page refresh
if (module.hot) {
	module.hot.accept()
}

/* Animate on scroll */
AOS.init({
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 100, // offset (in px) from the original trigger point
	delay: 0, // values from 0 to 3000, with step 50ms
	duration: 950, // values from 0 to 3000, with step 50ms
	easing: "ease-out-cubic", // default easing for AOS animations
	once: true, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
})

/* $.noConflict()
jQuery(document).ready(function () {
	jQuery("button").click(function () {
		jQuery("p").text("jQuery is still working!")
	})
}) */

/* Search and Filter */
;(function ($) {
	"use strict"

	$(document).on("sf:ajaxfinish", ".searchandfilter", function () {
		const filterContent = new FilterContent()
		filterContent.init()
	})
})(jQuery)

/* Change iframe height to 100% */
window.addEventListener("load", function () {
	let message = {
		height: document.body.scrollHeight,
		width: document.body.scrollWidth,
	}

	// window.top refers to parent window
	window.top.postMessage(message, "*")
})

let iframe = document.querySelector("#iframe")

if (iframe) {
	window.addEventListener(
		"message",
		function (e) {
			// message that was passed from iframe page
			let message = e.data
			iframe.style.height = message.height + "px"
		},
		false
	)
}
