import axios from "axios"

class Search {
	constructor() {
		this.openButton = document.querySelectorAll(".header__search-open")
		this.closeButton = document.querySelector(".header__search-close")
		this.searchForm = document.querySelector(".header__search-overlay")
		this.searchOverlay = document.querySelector(".overlay__search")
		this.searchField = document.querySelector("#search")
		this.isOverlayOpen = false
		this.events()
	}

	// Events
	events() {
		this.openButton.forEach(el => {
			el.addEventListener("click", e => {
				e.preventDefault()
				this.openOverlay()
			})
		})

		this.closeButton.addEventListener("click", () => this.closeOverlay())
		this.searchOverlay.addEventListener("click", () => this.closeOverlay())
	}

	// Methods
	openOverlay() {
		this.searchOverlay.classList.add("overlay__search--active")
		this.searchForm.classList.add("header__search-overlay--active")
		document.body.classList.add("h--no-scroll")
		this.searchField.value = ""
		setTimeout(() => this.searchField.focus(), 301)
		console.log("our open method just ran!")
		this.isOverlayOpen = true
		return false
	}

	closeOverlay() {
		this.searchOverlay.classList.remove("overlay__search--active")
		this.searchForm.classList.remove("header__search-overlay--active")
		document.body.classList.remove("h--no-scroll")
		console.log("our close method just ran!")
		this.isOverlayOpen = false
	}
}

export default Search
